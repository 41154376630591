import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  button: {
    margin: 15,
  },
  input: {
    marginTop: 30,
  },
  btn: {
    fontSize: "24px",
    color: "blue",
  },
});

class EditSpecial extends React.Component {
  state = {
    open: false,
    original: "",
    text: "",
  };

  componentDidMount() {
    this.setState({
      original: this.props.special.text,
      text: this.props.special.text,
    });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    // this.props.changeFunc(name, value);
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {
    this.props.app.setState({ loading: true });
    this.setState({
      open: false,
    });
    const op = [{ op: "replace", path: "/text", value: this.state.text }];
    axios
      .patch("/special/" + this.props.special._id, op)
      .then((res) => {
        // console.log(res.data);
        axios
          .get("/special")
          .then((res) => {
            this.props.adminApp.setState({ special: res.data });

            this.props.app.setState({ special: res.data, loading: false });
          })
          .catch((err) => {
            this.props.app.setState({ loading: false });
            // console.log(err);
          });
      })
      .catch((err) => {
        this.props.app.setState({ loading: false });
        // console.log(err);
      });
  };

  render() {
    // console.log(this.state);
    const { classes } = this.props;
    return (
      <div>
        <Button
          size="large"
          variant="outlined"
          className={classes.btn}
          endIcon={<EditIcon />}
          onClick={this.handleOpen}
        >
          編輯
        </Button>
        <Grid container justify="center">
          <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Grid container direction="column">
                <Grid item>
                  <TextField
                    className={classes.input}
                    fullWidth
                    value={this.state.text}
                    required
                    multiline
                    rows={10}
                    name="text"
                    variant="outlined"
                    label="內容"
                    onChange={this.onInputChange}
                  />
                </Grid>
                {/* </form> */}
              </Grid>
            </DialogContent>

            <DialogActions>
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    size="large"
                    disableRipple
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={this.handleClose}
                  >
                    取消
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="large"
                    disableRipple
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={this.state.original === this.state.text}
                  >
                    送出
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(EditSpecial);
