import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IntroPic from "./111年之蘆洲家醫海報.jpg";
import "../../index.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 16,
    margin: 10,
    width: 1200,
  },
}));

export default function Intro() {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Paper className={classes.paper}>
        <img src={IntroPic} width="100%" alt="110年之蘆洲家醫海報" />
        <div>
          現專職於
          <br />
          鴻文耳鼻喉科專科診所（衛生福利部中央健康保險署/家庭醫師整合性照護計畫/蘆洲社區醫療群）
        </div>
        <section>
          <h4>經歷</h4>
          <ul>
            <li>中華民國耳鼻喉科專科醫師</li>
            <li>三軍總醫院耳鼻喉部主治醫師</li>
            <li>
              加拿大多倫多大學耳鼻喉學系上呼吸道小組臨床研究員及醫研所進修
            </li>
            <li>英國威爾斯大學感冒及鼻研究中心臨床研究員及生研所博士班進修</li>
          </ul>
        </section>
      </Paper>
    </Grid>
  );
}
