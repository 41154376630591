import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { Link, withRouter } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  Drawer,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

const items = [
  {
    title: "首頁",
    url: "/",
    icon: <HomeIcon />,
  },
  {
    title: "管理",
    url: "/dashboard",
    icon: <VerifiedUserIcon />,
  },
  { title: "登出", icon: <ExitToAppIcon /> },
];

const styles = (theme) => ({
  fab: {
    margin: theme.spacing(1),
    position: "fixed",
    zIndex: 1200,
    [theme.breakpoints.down("sm")]: {
      bottom: 0,
      right: 0,
    },
    [theme.breakpoints.up("sm")]: {
      top: 0,
      right: 0,
    },
  },
});

class Menu extends React.Component {
  state = {
    show: false,
  };

  // componentDidMount() {
  //   axios
  //     .get("/admins/check-session")
  //     .then((res) => {
  //       this.setState({ loggedin: true });
  //       // console.log(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       // Redirect to home
  //       return false;
  //     });
  // }

  toggleDrawer = (open) => {
    this.setState({
      show: open,
    });
  };

  // Logout user and redirect to home
  logoff = () => {
    axios
      .get("/admins/logout")
      .then((res) => {
        console.log("logged out!");
        this.props.app.setState({ loggedin: false });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  render() {
    if (!this.props.loggedin) {
      return null;
    }
    const { classes } = this.props;

    const anchor = "right";
    const list = (
      <div
        onClick={this.toggleDrawer.bind(this, false)}
        onKeyDown={this.toggleDrawer.bind(this, false)}
      >
        <List>
          <ListItem button aria-label="close menu">
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
          {items.map((item) => {
            if (item.url) {
              return (
                <div key={item.url}>
                  <Link to={item.url} key={item.url}>
                    <ListItem divider button key={item.title}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  </Link>
                </div>
              );
            } else {
              // logoff
              return (
                <div key={item.title}>
                  <ListItem
                    divider
                    button
                    key={item.title}
                    onClick={() => this.logoff()}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItem>
                </div>
              );
            }
          })}
        </List>
      </div>
    );
    return (
      <div>
        <Tooltip title="選單" entertouchdelay={50}>
          <Fab
            color="primary"
            onClick={this.toggleDrawer.bind(this, true)}
            aria-label="open menu"
            className={classes.fab}
          >
            <MenuIcon />
          </Fab>
        </Tooltip>
        <Drawer
          anchor={anchor}
          open={this.state.show}
          onClose={this.toggleDrawer.bind(this, false)}
        >
          {list}
        </Drawer>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Menu));
