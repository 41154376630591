import React from "react";
import Delete from "./Delete";
import Edit from "./Edit";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    padding: 24,
    margin: 10,
    width: 1184,
  },
  title: {
    // fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#005742",
    wordBreak: "break-all",
  },
  content: {
    // fontSize: "20px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },
  time: {
    color: "#005742",
    fontSize: "16px",
  },
});

class PostList extends React.Component {
  render() {
    const { classes, post } = this.props;
    return (
      <Grid container justify="center">
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <div className={classes.title}>{post.title}</div>
            </Grid>
            <Grid item>
              <div className={classes.content}>{post.description}</div>
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <div className={classes.time}>{"更新：" + post.date}</div>
              </Grid>
            </Grid>
            {this.props.admin ? (
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="center"
                >
                  <Delete
                    post={post}
                    app={this.props.app}
                    adminApp={this.props.adminApp}
                  />
                  <Edit
                    changeFunc={this.onChange}
                    app={this.props.app}
                    adminApp={this.props.adminApp}
                    post={post}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(PostList);
