import React from "react";
import Post from "../Post";
import { CircularProgress, Grid } from "@material-ui/core";

class PostList extends React.Component {
  render() {
    const { admin, posts } = this.props;
    if (posts) {
      return posts.map((post) => (
        <Post key={post._id} app={this.props.app} adminApp={this.props.adminApp} post={post} admin={admin} />
      ));
    } else {
      return (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      );
    }
  }
}

export default PostList;
