import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid, IconButton, CircularProgress } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import EditSpecial from "./EditSpecial";

const styles = (theme) => ({
  paper: {
    padding: 24,
    margin: 10,
    width: 1184,
  },
  title: {
    // fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    color: theme.palette.error.main,
  },
  content: {
    // fontSize: "20px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },
  time: {
    color: "#005742",
    fontSize: "16px",
  },
  fontsize: {
    fontSize: "24px",
    color: theme.palette.primary.main,
  },
});

class Special extends React.Component {
  state = { public: false, text: "" };

  componentDidMount() {
    this.setState({ public: this.props.special.public, text: this.props.text });
  }

  handleChange = (event) => {
    // console.log(event.target.name, event.target.checked)
    this.props.app.setState({ loading: true });
    this.setState({
      open: false,
    });
    this.setState({
      [event.target.name]: event.target.checked,
    });
    const op = [
      { op: "replace", path: "/public", value: event.target.checked },
    ];
    axios
      .patch("/special/" + this.props.special._id, op)
      .then((res) => {
        // console.log(res.data);
        axios
          .get("/special")
          .then((res) => {
            this.props.adminApp.setState({ special: res.data });

            this.props.app.setState({ special: res.data, loading: false });
          })
          .catch((err) => {
            this.props.app.setState({ loading: false });
            // console.log(err);
          });
      })
      .catch((err) => {
        this.props.app.setState({ loading: false });
        // console.log(err);
      });
  };

  render() {
    const { special, classes } = this.props;
    return (
      <Grid container justify="center">
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.title}>
              <IconButton
                disableFocusRipple
                disableRipple
                className={classes.title}
                aria-hidden
              >
                <TodayIcon />
              </IconButton>
              特殊看診時間
            </Grid>
            <Grid item>
              {!special ? (
                <Grid container justify="center" color="primarry">
                  <CircularProgress />
                </Grid>
              ) : null}
            </Grid>
            <Grid item>
              {special ? (
                <div className={classes.content}>{special.text}</div>
              ) : null}
            </Grid>
            <Grid item>
              {special ? (
                <Grid container justify="flex-end">
                  <div className={classes.time}>
                    {"更新：" + special.update}
                  </div>
                </Grid>
              ) : null}
            </Grid>

            {this.props.admin ? (
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      className={classes.fontsize}
                    >
                      <Grid item>隱藏</Grid>
                      <Grid item>
                        <Switch
                          checked={this.state.public}
                          onChange={this.handleChange}
                          name="public"
                        />
                      </Grid>
                      <Grid item>公開</Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <EditSpecial
                      app={this.props.app}
                      adminApp={this.props.adminApp}
                      special={this.props.special}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(Special);
