import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";

const styles = (theme) => ({
  title: {
    margin: 15,
    wordBreak: "break-all",
  },
  delete: {
    fontSize: "24px",
    color: theme.palette.error.main,
  },
  button: {
    margin: 15,
  },
});

class Delete extends React.Component {
  state = { open: false };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDelete = () => {
    this.props.app.setState({ loading: true });
    this.setState({ open: false });
    axios
      .delete("/posts/" + this.props.post._id)
      .then((res) => {
        axios
          .get("/posts")
          .then((res) => {
            this.props.adminApp.setState({ posts: res.data });
            this.props.app.setState({ posts: res.data, loading: false });
          })
          .catch((err) => {
            this.props.app.setState({ loading: false });
            // console.log(err);
          });
      })
      .catch((err) => {
        this.props.app.setState({ loading: false });
        // console.log(err);
      });
  };

  render() {
    const { post, classes } = this.props;
    return (
      <div>
        <Button
          size="large"
          variant="outlined"
          endIcon={<DeleteIcon />}
          className={classes.delete}
          onClick={this.handleOpen}
          disabled={this.state.disabled}
        >
          刪除
        </Button>
        <Grid container justify="center">
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className={classes.title} id="alert-dialog-title">
              {"確定刪除公告「" + post.title + "」？"}
            </DialogTitle>
            <DialogActions>
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    size="large"
                    disableRipple
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={this.handleClose}
                  >
                    取消
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="large"
                    disableRipple
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={this.handleDelete}
                  >
                    確定
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(Delete);
