import Grid from "@material-ui/core/Grid";
// import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
// import {
//   FacebookShareButton,
//   LineShareButton,
//   FacebookIcon,
//   LineIcon,
// } from "react-share";

const useStyles = makeStyles((theme) => ({
  share: {
    marginTop: 40,
    marginBottom: 30,
    marginLeft: 20,
    marginRight: 20,
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  copyright: {
    fontSize: "x-small",
  },
}));

// const url = "https://www.honwenclinic.com/";

export default function Footer() {
  const classes = useStyles();
  // console.log(process.env);
  return (
    <footer>
      {/* <Grid container justify="center">
        <Tooltip title="分享至Facebook" placement="left" arrow>
          <FacebookShareButton url={url}>
            <FacebookIcon
              className={classes.share}
              size={40}
              round={true}
              entertouchdelay={50}
            />
          </FacebookShareButton>
        </Tooltip>
        <Tooltip
          title="分享至Line"
          placement="right"
          arrow
          entertouchdelay={50}
        >
          <LineShareButton url={url}>
            <LineIcon className={classes.share} size={40} round={true} />
          </LineShareButton>
        </Tooltip>
      </Grid> */}
      <Grid container justify="center" className={classes.copyright}>
        Copyright © 2022 Ya-Tzu Wang
      </Grid>

      <Grid container justify="center" className={classes.copyright}>
        All Rights Reserved.
      </Grid>
    </footer>
  );
}
