import "./index.css";
import { Link } from "react-router-dom";

import { Grid, AppBar, Toolbar, Typography } from "@material-ui/core";

export default function Bar() {
  return (
    <div>
      <AppBar position="fixed" elevation={0} className="color">
        <Toolbar variant="dense">
          <Grid container justify="center">
            <Grid item>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <Typography
                  style={{ fontFamily: "微軟正黑體" }}
                  variant="h4"
                  color="primary"
                >
                  <strong>
                    鴻文耳鼻喉科
                    <span id="clinic">診所</span>
                  </strong>
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}
