import React from "react";
import { withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";

const styles = (theme) => ({
  // white: {
  //   width: 500,
  // },
  paper: {
    marginTop: 64,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: 24,
    borderRadius: 4,
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  avatar: {
    margin: 8,
    backgroundColor: theme.palette.primary.light,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 8,
  },
  submit: {
    marginTop: 24,
    marginBottom: 16,
    marginLeft: 0,
    marginRight: 0,
    // margin: theme.spacing(3, 0, 2),
  },
});

class Login extends React.Component {
  // export default function Admin() {
  state = {
    loading: false,
    redirect: false,
    email: "",
    password: "",
    helperText: "",
  };

  onInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

  onLogin() {
    this.props.app.setState({
      loading: true,
    });
    const login = {
      email: this.state.email,
      password: this.state.password,
    };
    axios
      .post("/admins/login", login)
      .then((res) => {
        this.props.history.push("/dashboard");
        this.props.app.setState({
          loggedin: true,
          loading: false,
        });
        this.setState({ password: "" });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          password: "",
          helperText: "User not found. Please try again.",
        });
        this.props.app.setState({
          loading: false,
        });
      });
  }

  render() {
    const { classes } = this.props;
    // console.log(this.state);
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <VpnKeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log In
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={this.state.helperText !== ""}
              onChange={this.onInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={this.state.password}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={this.state.helperText !== ""}
              helperText={this.state.helperText}
              onChange={this.onInputChange}
            />
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.email === "" || this.state.password === ""}
              onClick={() => this.onLogin()}
            >
              Sign In
            </Button>
            {/* <Grid container justify="flex-end">
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(Login));
