import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid, Button, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";

const styles = (theme) => ({
  paper: {
    padding: 24,
    margin: 10,
    width: 1184,
  },
  input: {
    marginTop: 25,
  },
});

class New extends React.Component {
  state = {
    title: "",
    description: "",
  };

  handleSubmit = () => {
    this.props.app.setState({ loading: true });
    const post = {
      title: this.state.title,
      description: this.state.description,
    };

    // console.log("Submit Post", post);
    axios
      .post("/posts", post)
      .then((res) => {
        axios
          .get("/posts")
          .then((res) => {
            // console.log(res.data);
            // this.props.app.setState({ posts: res.data });
            this.props.adminApp.setState({ posts: res.data, tab: 1 });
            this.setState({
              title: "",
              description: "",
            });
            this.props.app.setState({ posts: res.data, loading: false });
          })
          .catch((err) => {
            this.props.app.setState({ loading: false });
            // console.log(err);
          });
        console.log("Add Post Success.");
      })
      .catch((err) => {
        this.props.app.setState({ loading: false });
        console.log("Add Post Failed.");
      });
  };

  onInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

  //   componentDidMount() {
  //   }

  render() {
    const { classes } = this.props;
    // console.log(this.state);
    return (
      <Grid container justify="center">
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <form noValidate autoComplete="off">
              <Grid item>
                <TextField
                  className={classes.input}
                  fullWidth
                  value={this.state.title}
                  required
                  error={this.state.title === ""}
                  name="title"
                  variant="outlined"
                  label="標題"
                  onChange={this.onInputChange}
                  helperText={this.state.title === "" ? "必填" : ""}
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.input}
                  fullWidth
                  value={this.state.description}
                  required
                  multiline
                  rows={10}
                  error={this.state.description === ""}
                  name="description"
                  variant="outlined"
                  label="內容"
                  onChange={this.onInputChange}
                  helperText={this.state.description === "" ? "必填" : ""}
                />
              </Grid>
              <Grid item>
                <Grid container justify="center">
                  <Button
                    size="large"
                    onClick={() => this.handleSubmit()}
                    className={classes.input}
                    variant="outlined"
                    color="primary"
                    endIcon={<AddIcon />}
                    disabled={
                      this.state.title === "" || this.state.description === ""
                    }
                  >
                    加至首頁
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(New);
