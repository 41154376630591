import { Grid, Tooltip, IconButton, Paper, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CallIcon from "@material-ui/icons/Call";
import RoomIcon from "@material-ui/icons/Room";
import MapIcon from "@material-ui/icons/Map";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";

const address = {
  icon: <RoomIcon />,
  title: "地址：",
  content: "新北市蘆洲區集賢路224巷14號",
  copyTip: "複製地址",
  copyText: "新北市蘆洲區集賢路224巷14號",
  href: "https://goo.gl/maps/9WVcA8dmhk6j5iCk7",
  actionTip: "開啟地圖",
  actionIcon: <MapIcon />,
};

const phone = {
  icon: <CallIcon />,
  title: "電話：",
  content: "(02) 8286-3222",
  copyTip: "複製電話",
  copyText: "0282863222",
  href: "tel:0282863222",
  actionTip: "撥打電話",
  actionIcon: <PhoneInTalkIcon />,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 16,
    margin: 10,
    width: 1200,
  },
  opa: {
    opacity: 0.5,
  },
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: "bold",
      textDecoration: "underline",
    },
  },
  color: {
    color: theme.palette.primary.main,
  },
}));

export default function Info() {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Paper className={classes.paper}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container alignItems="center" className={classes.color}>
              <IconButton
                disableFocusRipple
                disableRipple
                color="primary"
                aria-hidden
              >
                <ScheduleIcon />
              </IconButton>
              看診時間：
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item>
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                          週一至週六
                        </Grid>
                        <Grid item>
                          <Grid container direction="column" spacing={1}>
                            <Grid item>上午 8:00–12:00</Grid>
                            <Grid item>下午 3:30–6:30</Grid>
                            <Grid item>晚上 6:30–9:30</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid item>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                          週日
                        </Grid>
                        <Grid item>休診</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {[address, phone].map((item) => (
            <Grid item key={item.title}>
              <Tooltip
                title={item.actionTip}
                placement="left"
                entertouchdelay={50}
              >
                <a
                  className={classes.link}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton color="primary">{item.icon}</IconButton>
                  {item.title}
                </a>
              </Tooltip>
              {item.content}
              <Tooltip
                title={item.copyTip}
                placement="right"
                entertouchdelay={50}
              >
                <IconButton
                  color="primary"
                  className={classes.opa}
                  onClick={() => {
                    navigator.clipboard.writeText(item.copyText);
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
}
