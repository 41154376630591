import React from "react";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PostAddIcon from "@material-ui/icons/PostAdd";
import EventIcon from "@material-ui/icons/Event";
import SettingsIcon from "@material-ui/icons/Settings";
import New from "./New";
import PostList from "../PostList";
import Special from "../Special";
import axios from "axios";

const styles = (theme) => ({
  root: {
    margin: 10,
    width: 948,
  },
});

class Admin extends React.Component {
  // export default function Admin() {
  state = {
    tab: 1,
    posts: [],
    special: [],
  };

  componentDidMount() {
    axios
      .get("/admins/check-session")
      .then((res) => {
        // console.log(res.data);
        this.setState({ loggedin: true });
        axios
          .get("/posts")
          .then((res) => {
            // console.log(res.data);
            this.setState({ posts: res.data });
          })
          .catch((err) => {
            // console.log(err);
          });
        axios
          .get("/special")
          .then((res) => {
            // console.log(res.data);
            this.setState({ special: res.data });
          })
          .catch((err) => {
            // console.log(err);
          });
      })
      .catch((err) => {
        this.setState({ loggedin: false });
        // console.log(err);
        // Redirect to home
        this.props.history.push("/login");
        return;
      });
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab: newValue,
    });
  };

  // Renders the page based on what tab the user clicks
  renderTab = () => {
    if (this.state.tab === 0) {
      return <New admin adminApp={this} app={this.props.app} />;
    } else if (this.state.tab === 1) {
      return (
        <PostList
          admin
          adminApp={this}
          app={this.props.app}
          posts={this.state.posts}
        />
      );
    } else if (this.state.tab === 2) {
      return (
        <Special
          admin
          adminApp={this}
          app={this.props.app}
          special={this.state.special}
        />
      );
    }
  };

  render() {
    if (!this.props.loggedin) {
      return null;
    }
    // console.log(this.state);
    const { classes } = this.props;
    return (
      <div>
        <Grid container justify="center">
          <Paper className={classes.root}>
            <Tabs
              centered
              value={this.state.tab}
              onChange={this.handleTabChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab icon={<PostAddIcon />} label="新增公告" />
              <Tab icon={<SettingsIcon />} label="管理公告" />
              <Tab icon={<EventIcon />} label="特殊時間" />
            </Tabs>
          </Paper>
        </Grid>
        {this.renderTab()}
        {/* <New /> */}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Admin));
